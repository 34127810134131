import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { abi } from "../Constants/abi";
import { contractAddress } from "../Constants/ContractConstants";
import WalletContext from "./WalletContext";
import {
  errorToast,
  successToast,
  promiseToast,
  loadingToast,
} from "../Components/Toasts";
import { waitTransaction } from "./VerifyTransaction";
import toast from "react-hot-toast";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { walletProvider, web3, validateChain } = useContext(WalletContext);

  const [contract, setContract] = useState(null);
  const [transactionSuccessful, setTransactionSuccessful] = useState(false);
  const [error, setError] = useState(null);
  const [isAccountWhiteListed, setIsAccountWhiteListed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const toastId = useRef(null);
  const merkleProof = useRef([]);
  const getEthereumContract = async () => {
    const contract = await new web3.eth.Contract(abi, contractAddress);
    setContract(contract);
  };

  const getMerkleProof = async (account) => {
    const url = "https://cms.gothamlabs.co/api/v1/get-merkle-proof/";

    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wallet: account }),
      });

      if (!response.ok) {
        throw new Error(response);
      }
      const res = await response.json();
      const merkleProof = res?.proof;

      return merkleProof;
    } catch (error) {
      console.log("error", error);
      setIsAccountWhiteListed(false);
      errorToast("Your account is not whitelisted !!!");
    }
  };

  const createLogOnBackend = async (wallet, transaction_hash) => {
    const url = "https://cms.gothamlabs.co/api/v1/log-mint/";

    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ wallet, transaction_hash }),
      });

      if (!response.ok) {
        throw new Error(response);
      }
      const res = await response.json();
      console.log(res);
    } catch (error) {
      console.log("error", error);
      errorToast("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const balanceOf = async (walletAddress, tokenId = 1) => {
    try {
      const txn = await contract.methods
        .balanceOf(walletAddress, tokenId)
        .call();
      if (txn === "1") {
        throw Error("You already minted a token");
      }
    } catch (error) {
      console.log("error==>", error);
      errorToast(error.message || "Your transaction is failed");
      throw Error("");
    } finally {
      setIsLoading(false);
      toast.dismiss(toastId.current);
    }
  };

  const totalSupply = async (walletAddress, tokenId = 1) => {
    try {
      const txn = await contract.methods.total_supply().call();
    } catch (error) {
      console.log("eror==>", error);
      errorToast(error.message || "All tokens are already Minted");
    } finally {
      setIsLoading(false);
      toast.dismiss(toastId.current);
    }
  };

  const shouldUseMerkleProof = async () => {
    try {
      const result = await contract.methods.whiteListCheck().call();
      return result;
    } catch (error) {
      console.log("eror==>", error);
      errorToast(error.message || "All tokens are already Minted");
    } finally {
      setIsLoading(false);
      toast.dismiss(toastId.current);
    }
  };

  const mintToken = async (account) => {
    await validateChain();
    setIsLoading(true);

    try {
      const useMerkleProof = await shouldUseMerkleProof();

      if (useMerkleProof) {
        merkleProof.current = await getMerkleProof(account);

        if (!merkleProof.current) {
          return setError("Please provide valid wallet address");
        }
      }

      await balanceOf(account);
      const data = await contract.methods
        .mintDaoNFT(merkleProof.current)
        .encodeABI();
      let parms = {
        from: account,
        to: contractAddress,
        data: data,
      };
      toastId.current = loadingToast("Your transaction in progress");

      const transaction_hash = await walletProvider.current.request({
        method: "eth_sendTransaction",
        params: [parms],
      });

      const receipt = await waitTransaction(web3, transaction_hash);
      console.log("receipt--->", receipt);
      if (receipt?.status) {
        toast.dismiss(toastId.current);
        successToast("Transaction confirm");
        setTransactionSuccessful(true);
      } else {
        toast.dismiss(toastId.current);
        errorToast("Transaction Failed");
        setTransactionSuccessful(false);
      }
      // Send data to this api
      await createLogOnBackend(account, transaction_hash);
    } catch (error) {
      console.log("error ==>", error);
      errorToast(error.message || "Your transaction is failed");
      toast.dismiss(toastId.current);
    } finally {
      setIsLoading(false);
      toast.dismiss(toastId.current);
    }
  };

  useEffect(() => {
    getEthereumContract();
  }, []);

  return (
    <>
      <UserContext.Provider
        value={{
          mintToken,
          error,
          isAccountWhiteListed,
          isLoading,
          balanceOf,
          transactionSuccessful,
        }}
      >
        {children}
      </UserContext.Provider>
    </>
  );
};

export default UserContext;
