import React, { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { ErrorAlert } from "../Components/Alerts";
// import Modal from "../Components/Modal";
import UserContext from "../Context/UserContext";
// Context Import
import WalletContext from "../Context/WalletContext";
// Images
import Info from "../images/info.png";
import GIF from "../images/GIF.gif";
import twitter from "../images/twitter.svg";
import discord from "../images/discord.svg";

const Main = () => {
  const { currentAccount, connectToMetamask, connectWC, isWalletLoading } =
    useContext(WalletContext);
  const { mintToken, isAccountWhiteListed, isLoading, transactionSuccessful } =
    useContext(UserContext);
  // Modal Functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <main>
        <div className="main-wrapper">
          <div className="container">
            <div className="d-flex justify-content-center mb-5">
              {/* Twitter Link */}
              <a
                href="https://twitter.com/MalokaMetaverse"
                target="_blank"
                rel="noreferrer"
                className="mr-5"
              >
                <img src={twitter} alt="Maloka Twitter" />
              </a>
              {/* Discord Link */}
              <a
                href="https://discord.com/invite/malokametaverse"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={discord}
                  alt="Maloka Discord"
                  style={{
                    marginLeft: 20,
                  }}
                />
              </a>
            </div>
            {/* Twitter and Discord */}
            <div className="details-block">
              <h1>Claim Your Mindful Metaverse NFT</h1>
              <div className="info-block">
                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="info-img">
                      <img src={GIF || Info} alt="info" />
                    </div>
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="info-dt">
                      {isAccountWhiteListed ? (
                        <>
                          <p className="para1">
                            Only wallets who signed the Manifesto for Manifesto
                            in web3 before June 05, 2022 can mint. Nonetheless,
                            you can still sign the Manifesto and follow us on
                            Discord and Twitter to stay updated!
                          </p>
                          <p className="para2">
                            Holders of the Mindful Metaverse NFT get access to
                            exclusive events, allowlists for future NFT drops,
                            and other special perks.
                          </p>
                          <p className="price-block">
                            Price:
                            <span className="price-info">
                              {" "}
                              Free (+gas)
                            </span>{" "}
                          </p>
                          <p className="price-block">
                            Supply: <span className="price-info"> 15,000</span>{" "}
                          </p>
                          <span className="bottom-brd"></span>
                          {currentAccount ? (
                            <>
                              {isLoading ? (
                                <button className="btn-main">
                                  <Spinner animation="border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </Spinner>
                                </button>
                              ) : (
                                <>
                                  {!transactionSuccessful && (
                                    <button
                                      onClick={() => mintToken(currentAccount)}
                                      className="btn-main"
                                    >
                                      <span>MINT NOW</span>
                                    </button>
                                  )}
                                </>
                              )}

                              {transactionSuccessful && (
                                <a
                                  href={`https://opensea.io/collection/mindfulmetaverseportals`}
                                  target="_blank"
                                  className="btn-main "
                                  rel="noreferrer"
                                >
                                  <span>Success! View on Opensea</span>
                                </a>
                              )}
                            </>
                          ) : (
                            <>
                              <button className="btn-main" onClick={handleShow}>
                                <span>Connect to mint</span>
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        // ACCOUNT IS NOT WHITELISTED
                        <>
                          <p className="para1">
                            Uh oh! Looks like you’re not eligible to mint the
                            Mindful Metaverse NFT.
                          </p>
                          <p className="para1">
                            Don't worry, you can still follow us on Twitter and
                            join our Discord to get involved in what's next.
                          </p>

                          <div className="d-flex icon-container">
                            <a
                              href="https://twitter.com/MalokaMetaverse"
                              target="_blank"
                              rel="noreferrer"
                              className="btn-main"
                            >
                              <span>Follow On Twitter</span>
                            </a>
                            <a
                              href="https://discord.com/invite/malokametaverse"
                              target="_blank"
                              rel="noreferrer"
                              className="btn-main"
                            >
                              <span> Join Our Discord</span>
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* METAMASK AND WALLET CONNECT MODAL */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Connect Wallet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {isWalletLoading ? (
                  <div className="spinner-center">
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <div className="connect-walletButton-container">
                    <button
                      onClick={() => {
                        connectToMetamask();
                        handleClose();
                      }}
                      className="connect-wallet-button"
                      disabled={isWalletLoading}
                    >
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1180px-MetaMask_Fox.svg.png"
                        alt="Metamask Sign in"
                        width="160"
                        height="160"
                      />
                      Metamask
                    </button>
                    <button
                      onClick={() => {
                        connectWC();
                        handleClose();
                      }}
                      className="connect-wallet-button"
                      disabled={isWalletLoading}
                    >
                      <img
                        src="https://api.nuget.org/v3-flatcontainer/walletconnect.core/1.7.0/icon"
                        width="160"
                        height="180"
                        alt="Wallet Connect"
                      />
                      Wallet Connect
                    </button>
                  </div>
                )}
              </Modal.Body>
            </Modal>
            <div className="text-white d-flex justify-content-center text-center mt-5">
              Contract Address:
              <a
                href="https://etherscan.io/address/0x7c260717f5336f4b46a2d5c5b8af2163efb7960e"
                target="_blank"
                rel="noreferrer"
                className="text-white  underline"
              >
                0x7c260717f5336f4b46a2d5c5b8af2163efb7960e
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Main;
