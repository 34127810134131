const DEFAULT_INTERVAL = 10000;

const DEFAULT_BLOCKS_TO_WAIT = 0;

/**
 * Wait for one transactions to confirm.
 *
 * @param web3
 * @param txnHash A transaction hash
 * @param options Wait timers
 * @return Transaction receipt
 */

export function waitTransaction(web3, txnHash, options = null) {
  const interval =
    options && options.interval ? options.interval : DEFAULT_INTERVAL;
  const blocksToWait =
    options && options.blocksToWait
      ? options.blocksToWait
      : DEFAULT_BLOCKS_TO_WAIT;
  var transactionReceiptAsync = async function (txnHash, resolve, reject) {
    try {
      var receipt = await web3.eth.getTransactionReceipt(txnHash);
      if (!receipt) {
        setTimeout(function () {
          transactionReceiptAsync(txnHash, resolve, reject);
        }, interval);
      } else {
        if (blocksToWait > 0) {
          var resolvedReceipt = await receipt;
          if (!resolvedReceipt || !resolvedReceipt.blockNumber)
            setTimeout(function () {
              transactionReceiptAsync(txnHash, resolve, reject);
            }, interval);
          else {
            try {
              var block = await web3.eth.getBlock(resolvedReceipt.blockNumber);
              var current = await web3.eth.getBlock("latest");
              if (current.number - block.number >= blocksToWait) {
                var txn = await web3.eth.getTransaction(txnHash);
                if (txn.blockNumber != null) resolve(resolvedReceipt);
                else
                  reject(
                    new Error(
                      "Transaction with hash: " +
                        txnHash +
                        " ended up in an uncle block."
                    )
                  );
              } else
                setTimeout(function () {
                  transactionReceiptAsync(txnHash, resolve, reject);
                }, interval);
            } catch (e) {
              setTimeout(function () {
                transactionReceiptAsync(txnHash, resolve, reject);
              }, interval);
            }
          }
        } else resolve(receipt);
      }
    } catch (e) {
      reject(e);
    }
  };

  return new Promise(function (resolve, reject) {
    transactionReceiptAsync(txnHash, resolve, reject);
  });
}
