export const contractAddress = "0x7c260717F5336F4B46A2d5C5B8af2163efB7960e";

export const infura_RPC_URL_Mainnet =
  "https://mainnet.infura.io/v3/4bcc5ab558154b4eb82b71d9d72a9f73";
export const infura_RPC_URL_Rinkeby =
  "https://rinkeby.infura.io/v3/4bcc5ab558154b4eb82b71d9d72a9f73";

export const infura_RPC_URL_Polygon_Mainnet =
  "https://polygon-mainnet.infura.io/v3/91c4e708571847948bb37155c7fd25fc";
export const infura_RPC_URL_Polygon_Mumbai =
  "https://polygon-mumbai.infura.io/v3/91c4e708571847948bb37155c7fd25fc";

export const network_id = {
  mumbai_polygon: 0x13881,
  mainnet_polygon: 0x89,
  rinkeby_eth: 0x4,
  mainnet_eth: 0x1,
};
