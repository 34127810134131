import toast from "react-hot-toast";

export const successToast = (message) => toast.success(message);
export const errorToast = (message) => toast.error(message);
export const loadingToast = (message) => toast.loading(message);

export const promiseToast = (myPromise) =>
  toast.promise(myPromise, {
    loading: "Please wait while your transaction is processing !!!",
    success: "Your Transaction is successfully made",
    error: "You Transaction was failed",
  });
