import React, { createContext, useEffect, useRef, useState } from "react";
import Web3 from "web3";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { infura_RPC_URL_Mainnet } from "../Constants/ContractConstants";
import { Web3Provider } from "@ethersproject/providers";
import { errorToast, successToast } from "../Components/Toasts";
export const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [chainId, setChainId] = useState(["0x1", "1", 1]);
  const walletProvider = useRef();

  const [isWalletLoading, setIsWalletLoading] = useState(false);
  const [walletError, setWalletError] = useState(null);

  const web3 = new Web3(infura_RPC_URL_Mainnet);
  window.we = web3;

  // Connect to Metamask
  const connectToMetamask = async () => {
    try {
      if (!window.ethereum) return setWalletError("Please install metamask");
      setIsWalletLoading(true);
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      setCurrentAccount(accounts[0]);
      walletProvider.current = window.ethereum;

      //ValidateChain
      validateChain();

      setIsWalletLoading(false);

      successToast("Wallet is Successfully connected !!!");
    } catch (error) {
      console.log("metamask error --->", error, error.message);
      setIsWalletLoading(false);
      setWalletError(error.message);
      errorToast(error.message);
    } finally {
      setIsWalletLoading(false);
    }
  };

  //swithChain
  const validateChain = async () => {
    const fetchChainId = walletProvider.current.chainId;

    if (!chainId.includes(fetchChainId)) {
      errorToast("Please switch to Ethereum Mainnet ");
      throw new Error("invalid chain id");
    }
  };

  const handleAutoConnectWallet = async () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          console.error(err, "NOT CONNECTED");
        });

      window.ethereum.on("accountsChanged", handleAccountsChanged);
      function handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          console.log("Please connect to MetaMask.");
          setCurrentAccount("");
        } else if (accounts[0] !== currentAccount) {
          setCurrentAccount(accounts[0]);
          walletProvider.current = window.ethereum;
        }
      }
      return true;
    }
    return false;
  };

  //Wallet Connect

  const connectWC = async () => {
    walletProvider.current = null;
    setCurrentAccount(null);
    setIsWalletLoading(true);
    try {
      const walletConnector = new WalletConnectProvider({
        rpc: {
          1: "https://mainnet.infura.io/v3/4bcc5ab558154b4eb82b71d9d72a9f73",
          4: "https://rinkeby.infura.io/v3/4bcc5ab558154b4eb82b71d9d72a9f73",
        },
        qrcode: true,
        pollingInterval: 15000,
      });

      const provider = new Web3Provider(walletConnector, "any");
      // setIsWalletLoading(false);
      // walletProvider.current = provider.provider;

      // Show QR Code
      const accounts = await walletConnector.enable();
      // const provider = new Web3(walletConnector);
      setIsWalletLoading(false);
      walletProvider.current = walletConnector;
      window.w3 = provider.provider;
      setCurrentAccount(accounts[0]);
      successToast("Wallet is Successfully connected !!!");
    } catch (ex) {
      console.log(ex);
      setIsWalletLoading(false);
      errorToast("Your wallet is failed to connect");
    } finally {
      setIsWalletLoading(false);
    }
  };

  useEffect(() => {
    handleAutoConnectWallet();
  }, []);

  return (
    <>
      <WalletContext.Provider
        value={{
          currentAccount,
          chainId,
          walletProvider,
          walletError,
          isWalletLoading,
          connectToMetamask,
          web3,
          connectWC,
          validateChain,
        }}
      >
        {children}
      </WalletContext.Provider>
    </>
  );
};

export default WalletContext;
