import { UserProvider } from "./Context/UserContext";
import { WalletProvider } from "./Context/WalletContext";
import Main from "./Pages/Main";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      <WalletProvider>
        <UserProvider>
          <Main />
        </UserProvider>
      </WalletProvider>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
